import React from 'react';
import fiddler from "../../../Assets/Fiddler_car.pdf"

class PDFViewer extends React.Component {
    render() {
        return (
            <div className='' style={{ height: "100vh", width: "100%" }}>
                <embed
                    type="application/pdf"
                    src={fiddler}
                    width="100%"
                    height="100%"
                /></div>
        );
    }
}

export default PDFViewer;
