import React, { useEffect, useRef, useState } from "react";
import Icons from "../../../Constants/Icon";
import "./ChaletsByType.css";
import BackgroundImage from "../../../component/backgroundtext/backgroundimg";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";
import { BaseUrl } from "../../../Core/Until";

const spinnerOverride = css`
  display: block;
  margin: 0 auto;
`;
function ChaletsByType() {
  const { t, i18n } = useTranslation();
  const currentLanguageCode = i18n.language;
  const [listings, setListings] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageComments, setPageComments] = useState(0);
  const [paginationClose, setPaginationClose] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setListings([]);
    setPageComments(0);
    fetchListings(0);
    window.scrollTo(0, 0);
  }, [location]);

  const fetchListings = async (nextPage) => {
    if (loading) return;
    document.body.style.overflow = "hidden";
    try {
      setLoading(true);
      const response = await axios.post(`${BaseUrl}api/listings`, {
        offset: 25 * nextPage,
        limit: 25,
        category: location?.state?.searchChalet ?? "Rabbit",
      });
      console.log("response?.data?.data?.listings",response?.data?.data?.listings);
      
      if (response?.data?.data?.listings?.length) {
        const newListings = response?.data?.data?.listings.map((listing) => {
          let descriptionText = "";
          let propertyTitle = "";
          let features = {
            bedrooms: [],
            bathrooms: 0,
            amenities: [],
            squareFeet: "",
            guestsMax: 0,
          };

          if (listing?.description) {
            const description = listing.description;
            const splitDescription = description
              .split("\n")
              .map((item) => item.trim())
              .filter(Boolean);
            if (
              splitDescription[splitDescription.length - 2].includes(
                "CITQ Registration number"
              )
            ) {
              splitDescription.splice(splitDescription.length - 2, 2);
            }
            propertyTitle = splitDescription[0];
            descriptionText = splitDescription
              .slice(1, splitDescription.indexOf("Property features:"))
              .join("<br/><br/>");
            descriptionText = descriptionText.replace(/\n/g, "<br/>");
            const propertyFeatures = splitDescription.slice(
              splitDescription.indexOf("Property features:") + 1
            );
            propertyFeatures.forEach((item) => {
              const cleanedItem = item.replace(/^[•\-\t\s]+/, "").trim();
              if (/Bedroom/i.test(cleanedItem)) {
                features.bedrooms.push(cleanedItem);
              } else if (/bathroom/i.test(cleanedItem)) {
                features.bathrooms = parseInt(
                  cleanedItem.match(/\d+/)?.[0] || 0,
                  10
                );
              } else if (/square feet/i.test(cleanedItem)) {
                features.squareFeet = cleanedItem.match(/\d+/)?.[0];
              } else if (/guests maximum/i.test(cleanedItem)) {
                features.guestsMax = parseInt(
                  cleanedItem.match(/\d+/)?.[0] || 0,
                  10
                );
              } else {
                features.amenities.push(cleanedItem);
              }
            });
            features.amenities = features.amenities.slice(
              0,
              features.amenities.length - 3
            );
          }

          return {
            websiteListingUid: listing?.websiteListingUid,
            images: listing.images.slice(0, 3),
            propertyName: listing.listingName.replace(/^Chalet\s+/i, ""),
            // propertyName: listing.propertyName,
            descriptionText,
            propertyTitle,
            features,
          };
        });

        newListings.sort((a, b) => {
          const numA = parseInt(a.propertyName.match(/\d+/)?.[0] || 0, 10);
          const numB = parseInt(b.propertyName.match(/\d+/)?.[0] || 0, 10);
          return numA - numB;
        });
        setListings((prev) => [...prev, ...newListings]);
      }
      setPaginationClose(response?.data?.data?.searchCompleted);
    } catch (error) {
      setListings([]);
      setPageComments(0);
      console.error("Error fetching listings:", error);
    } finally {
      setTimeout(() => {
        document.body.style.overflow = "unset";
        setLoading(false);
      }, 1000);
    }
  };

  const chaletTypeData = [
    {
      name: location?.state?.searchChalet ?? "Rabbit",
      height: "100vh",
      bgimg:
        location?.state?.searchChalet == "Rabbit"
          ? Icons.chalets_rabbit
          : location?.state?.searchChalet == "Bear"
          ? Icons.chalets_bear
          : location?.state?.searchChalet == "Deer"
          ? Icons.chalets_deer
          : location?.state?.searchChalet == "Moose" ?
          Icons.chalets_moose : Icons.Elk,
    },
  ];

  useEffect(() => {
    const handleWindowScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const clientHeight =
        window.innerHeight || document.documentElement.clientHeight;
      const scrollHeight = document.documentElement.scrollHeight;

      if (scrollTop + clientHeight + 800 >= scrollHeight) {
        if (!paginationClose && !loading) {
          setPageComments((prevPage) => {
            const nextPage = prevPage + 1;
            fetchListings(nextPage);
            return nextPage;
          });
        }
      }
    };

    const throttleScroll = () => {
      if (!loading) {
        requestAnimationFrame(handleWindowScroll);
      }
    };

    window.addEventListener("scroll", throttleScroll);
    return () => window.removeEventListener("scroll", throttleScroll);
  }, [paginationClose, loading]);

  const urls = {
    en: "https://reservations.fiddlerlakeresort.com/",
    fr: "https://reservations.fiddlerlakeresort.com/fr/",
  };
  const currentUrl = urls[currentLanguageCode] || urls.en;


  return (
    <div className="min_ChaletsByType">
      <section className="ACTIVITIES_data family_fillte ">
        <BackgroundImage Sauveur={chaletTypeData} />
      </section>

      {listings.map((chalet, index) => (
        <section
          style={{ paddingLeft: 35, paddingRight: 35 }}
          className="container"
          key={index}
        >
          <div className="">
            <h2 className="chalet-title Alfarn text-48">
              {chalet.propertyName}
            </h2>
          </div>
          <div>
            <div className="image-gallery ">
              <div className="main-image animation_img">
                <img
                  src={chalet.images[0].large}
                  className="img_animation"
                  alt="Main Chalet View"
                />
              </div>
              <div className="thumbnail-images">
                {chalet.images.slice(1).map((imgSrc, imgIndex) => (
                  <div key={imgIndex} className="thumbnail animation_img">
                    <img
                      className="h-full w-full img_animation"
                      src={imgSrc.large}
                      alt={`Chalet view ${imgIndex + 2}`}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="chalet-content">
              <div className="chalet-info">
                <p className="chalet-location  text-20  AkagiPro-book">
                  {t(chalet?.propertyTitle)}
                </p>

                {/* {chalet?.titleDescription && <p className='chalet-location mt-3 text-20  AkagiPro-book'>{t(chalet?.titleDescription)}</p>} */}

                <p
                  className="chalet-location text-20  AkagiPro-book"
                  dangerouslySetInnerHTML={{ __html: chalet?.descriptionText }}
                >
                  {/* {chalet?.descriptionText} */}
                </p>
                {/* https://reservations.fiddlerlakeresort.com/property/HOBN39D4HEDF7ZR0 */}
                <Link to={`${currentUrl}property/${chalet?.websiteListingUid}`}>
                  <button className=" uppercase bg-lightyellow text-white  py-8 max-[767px]:bg-sky-768 max-[767px]:w-16 book_button md:mx-2 book_now AkagiPro-Black text-20 book-button">
                    {t("book now")}
                  </button>
                </Link>
              </div>
              <div className="property-features">
                <h4>{t("Property Features:")}</h4>
                <ul className="text-20">
                  <li>{chalet?.features?.bedrooms[0]}</li>
                  {chalet?.features?.bedrooms.slice(1).map((item, index) => (
                    <p className="chalet-location bathrooms" key={index}>
                      {item}
                    </p>
                  ))}
                  <li>{chalet?.features?.bathrooms} bathrooms</li>
                  {chalet?.features?.amenities.map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                  <li>{chalet?.features?.squareFeet} square feet</li>
                  <li>{chalet?.features?.guestsMax} guests maximum</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      ))}

      {listings.length == 0 &&  (
        <h2
          style={{ textAlign: "center", }}
          className="uppercase p-3 text-lightyellow bg-sky-768 max-w-[90%] w-auto mx-auto sm:max-w-[60%] text-center AkagiPro-Black text-20"
          // className="uppercase mb-5 text-lightyellow   max-[767px]:bg-sky-768 max-[767px]:w-16   AkagiPro-Black text-20"
        >
          {t("Not Any Chalets Found")}
        </h2>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(0, 0, 0, 0.88)",
          zIndex: 9999999999999,
          visibility: loading ? "visible" : "hidden",
        }}
      >
        <div>
          <ClipLoader
            color={"#FFF"}
            loading={true}
            css={spinnerOverride}
            size={35}
          />
        </div>
      </div>
    </div>
  );
}

export default ChaletsByType;
