import React, { useState, useEffect } from 'react';
import Bookfooter from "../../../component/footer/Bookfooter";
import CursorSlider from "../../../component/CustomSlide/chalersslider";
import Icons from "../../../Constants/Icon";
import BookNow from '../../../component/Button/BookNow';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BackgroundImage from "../../../component/backgroundtext/resortsbg";
import { useNavigate } from 'react-router-dom';
const restaurants = [
    {
        name: "Nearby",
        title: " Sports",
        titlename: "<ul ><li>Sommet Saint-Sauveur</li><li>Sommet Morin-Heights</li><li>Club de Golf Lachute</li></ul>   ",
        Image: Icons.Restaurants3,
    },

    {
        name: "Nearby",
        title: "Entertainment",
        titlename: "<ul><li>Sommet Saint-Sauveur Water Park</li><li>Acro-Nature</li><li>Val-David Summer Market</li></ul>",
        Image: Icons.Restaurants1,
    },
    {
        name: "Nearby",
        title: "Spas",
        titlename: "<ul><li>Ofuro Station ZEN</li> <li>Strøm spa nordique</li><li>Amerispa Morin-Heights</li></ul>",
        Image: Icons.Restaurants2,
    },


    {
        name: "Nearby",
        title: "Stores",
        titlename: "<ul><li>Au Clair de la Lune Boutique</li><li>Tanger Factory Outlets</li><li>Desjardins Marine</li></ul>",
        Image: Icons.Restaurants,
    },


    {
        name: "Nearby",
        title: "Restaurants",
        titlename: "<ul><li>Maestro</li><li>Gibbys</li><li>Steak Frites St-Paul</li></ul>",
        Image: Icons.GibbysSauveurPic,
    },
]
const beardata = [{
    img: Icons.home,
    name: "rabbit",
    title: "These bright and comfortable chalets are perfect for retreats with friends or family. Create endless memories in this charming chalet."
    , Vectors: Icons.Vectorcagaru,
    lanermor: "/discoverchalets",
    id: "1",
    while: "53px"

},
{
    img: Icons.home1,
    name: "bear",
    title: "This model caters to guests looking to combine luxury and adventure. With its majestic windows, our Bear chalet offers breathtaking views of nature."
    , Vectors: Icons.Vectorrish,
    id: "2",
    lanermor: "/discoverchalets",
    while: "70px"

},
{
    img: Icons.homer,
    name: "deer",
    title: "Indulge in a spacious, two-storey chalet with plenty of room for family, friends, or colleagues. This elegant model has cathedral ceilings and panoramic windows, and all the extras to make your stay unforgettable."
    , Vectors: Icons.Vector8,
    id: "3",
    lanermor: "/discoverchalets",

},
{
    img: Icons.home1,
    name: "moose",
    title: "These larger chalets are the perfect retreat for corporate retreats and larger groups. Our Moose chalet is sure to amaze you with its majestic and impressive structure."
    , Vectors: Icons.Vector9,
    id: "4",
    lanermor: "/discoverchalets",
    while: "60px"

},

{
    img: Icons.home1,
    name: "elk",
    title: "Come experience the splendour of nature in this exclusive chalet. This beautifully appointed model provides luxury and plenty of space so you can host a corporate event or accommodate a large family."
    , Vectors: Icons.Vector10,
    id: "5",
    lanermor: "/discoverchalets",

},
]
function LocalactivitiesDining() {
    const [responsiveHeight, setResponsiveHeight] = useState(
        window.innerWidth > 768 ? "70vh" : "30vh"
    );
    useEffect(() => {        const handleResize = () => {
            const newHeight = window.innerWidth > 768 ? "70vh" : "30vh";
            setResponsiveHeight(newHeight);
        };
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    const handleLinkClick = () => { }
    const { t } = useTranslation();
    const navigate = useNavigate();
    const handleRedirect = (item) => {
        navigate('/discoverchalets');
    };
    const Depending = [{
        name: " ",
        height: "100vh",
        bgimg: Icons.LocalActivities,
        Rentals: "Local activities & Dining ",
        width: "1200px",
    }]
    return (
        <div>
            <section className='ACTIVITIES_data family_fillte'>
                <BackgroundImage Depending={Depending} />
            </section>
            <section className=" bg-skyYellow md:pt-20 md:pb-20 max-[767px]:py-10" >
                <div className="container">
                    <p className="text-20 AkagiPro-Black text-center"><div dangerouslySetInnerHTML={{ __html: t("From skiing and golfing to spas and waterparks, the Saint-Sauveur Valley is a bustling destination with a lot to offer to visitors of all ages. For a complete experience, explore the region’s diverse array of restaurants to discover local hidden gems and fine dining favourites!") }} /></p>
                </div>
            </section >

            <section id="service" className="md:mb-[370px] max-[767px]:mb-8">
            </section>
            <section className='container relative px-8   restaurants_h '>
                <div className='flex flex-wrap justify-center  grid-cols-3 gap-4 md:-mt-10 md:-mb-[120px]  max-[767px]:mb-10'>
                    {restaurants.map((item, index) => (


                        <div style={{ width: "32%" }} className="Massage md:mb-[300px] max-[767px]:mb-3 md:relative"  >
                            <div className='animation_img md:absolute md:-mt-64'>
                                <img src={item.Image} className={item.anform ? `${(item.anform)}` : "w-full relative  img_animation"} style={{ transform: `${(item.transform)}` }} /></div>
                            <div className='p-7 md:h-full  max-[767px]:pl-12 text-white bg-lightyellow relative z-10  max-[767px]:-mt-20' style={{ width: "90%" }}>
                                <h3 className="AkagiPro-Black text-20  uppercase  "> {t(item.name)}</h3>
                                <p className='text-32 lg:-mt-0  max-[767px]:mt-1 md:mt-1 md:mb-1  lg:-mb-0  max-[767px]:mb-1 AkagiPro-Black '>{t(item.title)}</p>
                                <div className='text-20'>
                                    <div dangerouslySetInnerHTML={{ __html: t(item.titlename) }} /></div>
                                {BookNow.map((BookNow) => (<Link to='/discoverchalets' onClick={handleLinkClick} className='text-white' style={{ display: "none" }}>      <h3 className="AkagiPro-Black md:ml-4 text-20 md:-mb-2 capitalize md:mt-9 max-[767px]:mt-5 flex items-baseline gap-2 cursor-pointer ">{t("view more")} <img src={Icons.whitetir} className="max-[767px]:w-6" /></h3></Link>))}

                            </div>

                        </div>
                    ))} </div>
            </section >
            <section className="text-center md:-mt-52  px-8 max-[767px]:mt-1"  >
                <div className='container'>
                    <h1 className="text-black mt-2 md:mb-5  text-64 Alfarn">{t("Discover our chalets")}</h1>
                    <p className="text-20 AkagiPro-book md:mt-4">{t("Escape to one of our luxurious chalets, each boasting floor-to-ceiling windows, a stone fireplace, and an outdoor patio equipped with a BBQ.")}</p>
                    <p className="text-20 AkagiPro-book md:mt-4" dangerouslySetInnerHTML={{ __html: t("Our models range in size from three to six rooms, and depending on the chalet chosen, additional features may include a three- or four-season veranda,<br />private hot tub, private sauna, pool table, or even a private pool.") }} />
                    <p className="text-20 AkagiPro-book md:mt-4">{t("Read each model’s description for specific features and detailed information.")}</p>
                </div>
            </section>
            <section className='relative   w-full md:mt-8  max-[767px]:mt-5 md:mb-0 max-[767px]:mb-0 md:pl-14 pl-0 overflow-hidden pb-[150px] ' >
                <div>
                    <CursorSlider beardata={beardata} handleRedirect={handleRedirect} />
                </div>
            </section>
            <Bookfooter />
        </div >
    )
}
export default LocalactivitiesDining;