import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import Icons from "../../Constants/Icon";

const isSafari = () => {
  const ua = navigator.userAgent.toLowerCase();
  return ua.indexOf("safari") > -1 && ua.indexOf("chrome") < 0;
};

export default function Hompeslider() {


  const [shouldUseImage, setShouldUseImage] = useState(false);
  const { t } = useTranslation();

  const videoRef = useRef(null);

  useEffect(() => {
    if (isSafari() && videoRef.current) {
      const player = videoRef.current.children[0];
      if (player) {
        player.controls = false;
        player.playsinline = true;
        player.muted = true;
        player.setAttribute("muted", ""); 
        player.autoplay = true;
        setTimeout(() => {
          const promise = player.play();
          if (promise.then) {
            promise
              .then(() => {})
              .catch(() => {
                videoRef.current.style.display = "none";
                setShouldUseImage(true);
              });
          }
        }, 0);
      }
    }
  }, []);
  return (
    <div className="relative data_img overflow-hidden">
      <div className="absolute w-full h-full flex justify-center items-center z-10 top-0">
          <div className="text-center z-10 text-white">
            <h3 class=" Alfarn text-48 ">{t('FIDDLER LAKE RESORT')}</h3>
          </div>
      </div>

            <div className="relative" >
              {  shouldUseImage ? (
                  <img src={Icons.website} alt="Muted Video" />
                ) : (
                  <div
                  style={{  width: '100%' }}
                    ref={videoRef}
                    dangerouslySetInnerHTML={{
                      __html: `
        <video
          loop
          className="data_img h-full w-full object-cover Depending_img slider_img"
          muted
          autoplay
          playsinline
          preload="metadata"
        >
        <source src="${Icons.website}" type="video/mp4" />
        </video>`,
                    }}
                  />
                )
            }
            <div className="black_s"></div>
            </div>
        
    </div>
  );
}
