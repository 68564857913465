import React, { useState, useEffect } from "react";
import {
  Routes,
  Route,
  useLocation,
  Navigate
} from "react-router-dom";
import Home from "./App/Screens/MainScreen/Home/Home";
import Header from "./App/Screens/MainScreen/Header/Headers";
import "./index.css";
import "./App.css";
import Experience from "./App/Screens/MainScreen/Experience/Experience";
import Groups from "./App/Screens/MainScreen/groups/groups";
import Contactus from "./App/Screens/MainScreen/Contactus/Contactus";
import Loader from "./App/component/Loader/Loader";
import Footer from "./App/component/footer/footer";
import LocalactivitiesDining from "./App/Screens/MainScreen/ActivitiesRestaurants/LocalactivitiesDining";
import Resortactivities from "./App/Screens/MainScreen/ActivitiesRestaurants/resortactivities";
import Familyfriends from "./App/Screens/MainScreen/Home/family&friends/family&friends";
import Weddings from "./App/Screens/MainScreen/Home/Weddings/Weddings";
import Corporate from "./App/Screens/MainScreen/Fiddler_Like_Resort/corporate";
import DiscoverChalets from "./App/Screens/MainScreen/Discover/DiscoverChalets";
import i18n from "./i18n";
import ScrollToTop from "./App/Screens/MainScreen/ScrollToTop/ScrollToTop";
import Resortservices from "./App/Screens/MainScreen/Resortservices/resortservices";
import PDFViewer from "./App/Screens/MainScreen/Contactus/PDFViewer";
import Faq from "./App/Screens/MainScreen/faq/faq";
import PrivacyPolicy from "./App/Screens/MainScreen/PrivacyPolicy/privacypolicy";
import Termsofservice from "./App/Screens/MainScreen/PrivacyPolicy/termsofservice";
import { initGA, trackPageLoadTime } from "./App/analytics";
import TermsAndConditions from "./App/Screens/MainScreen/TermsAndConditions/TermsAndConditions";
import { setLoadingFalse, setLoadingTrue } from "./App/Config/Actions/loadingActions";
import { useDispatch } from "react-redux";
import ChaletsByType from "./App/Screens/MainScreen/ChaletsByType/ChaletsByType";
function App() {

  const defaultLanguage = "English";
  const location = useLocation();
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("selectedLanguage");
    if (storedLanguage) {
      return storedLanguage;
    } else {
      localStorage.setItem("selectedLanguage", defaultLanguage);
      return defaultLanguage;
    }
  });
  const dispatch = useDispatch();
  useEffect(() => {
    if (location.pathname == "/fr" || location.pathname == "/fr/") {
      localStorage.setItem("selectedLanguage", "fr");
      i18n.changeLanguage("fr");
    } else if (location.pathname == "/en" || location.pathname == "/en/") {
      localStorage.setItem("selectedLanguage", "en");
      i18n.changeLanguage("en");
    }
    else {
      localStorage.setItem("selectedLanguage", selectedLanguage);
      i18n.changeLanguage(selectedLanguage);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    initGA();
    trackPageLoadTime();
  }, []);

  const showHeaderFooter =
    location.pathname != "/tnc" && location.pathname !== "/tnc/";
  useEffect(() => {

    
    
    const handleLinkClick = (e) => {
      if(location.pathname == "/chaletsByType"){
        return
      }
      const target = e.target.closest('a');
      if (target && target.href) {
        dispatch(setLoadingTrue());
        setTimeout(() => {
          dispatch(setLoadingFalse());
        }, 2000);
      }
    };
    const handleNavigationEnd = () => {
      dispatch(setLoadingFalse());
    };
    document.addEventListener('click', handleLinkClick);
    handleNavigationEnd();
    return () => {
      document.removeEventListener('click', handleLinkClick);
    };
  }, [location, dispatch]);

  return (
    <div>
      <div>
        <Loader />
        <div>
          {showHeaderFooter && (
            <Header />
          )}
          <ScrollToTop>
            <PageTracker />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Experience" element={<Experience />} />
              <Route path="/groups" element={<Groups />} />
              <Route path="/Contactus" element={<Contactus />} />
              <Route path="/localactivitiesdining" element={<LocalactivitiesDining />} />
              <Route path="/family&friends" element={<Familyfriends />} />
              <Route path="/Weddings" element={<Weddings />} />
              <Route path="/corporate" element={<Corporate />} />
              <Route path="/DiscoverChalets" element={<DiscoverChalets />} />
              <Route path="/resortservices" element={<Resortservices />} />
              <Route path="/resortactivities" element={<Resortactivities />} />
              <Route path="/faq" element={<Faq />} />
              <Route path="/PDFViewer" element={<PDFViewer />} />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/termsofservice" element={<Termsofservice />} />
              <Route path="/tnc" element={<TermsAndConditions />} />
              <Route path="/fr" element={<Navigate to="/" />} />
              <Route path="/" element={<Home />} />
              <Route path="/chaletsByType" element={<ChaletsByType />} />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </ScrollToTop>
          {showHeaderFooter && <Footer />}
        </div>
      </div>
    </div>
  );
}
const PageTracker = () => {
  const location = useLocation();
  useEffect(() => {
    if (window.gtag) {
      const pageTitle = getPageTitle(location.pathname);
      window.gtag("config", "G-Y5ZP3RJWK6", {
        page_path: location.pathname,
        page_title: pageTitle,
      });
    }
  }, [location]);

  return null;
};

const getPageTitle = (pathname) => {
  switch (pathname) {
    case "/":
      return "Home Page";
    case "/experience":
      return "Experience";
    case "/groups":
      return "Groups";
    case "/contactus":
      return "Contact Us";
    case "/family&friends":
      return "Family & Friends";
    case "/weddings":
      return "Weddings";
    case "/corporate":
      return "Corporate";
    case "/discoverchalets":
      return "Discover Chalets";
    case "/discover":
      return "Discover";
    case "/resortservices":
      return "Resort Services";
    case "/resortactivities":
      return "Resort Activities";
    case "/faq":
      return "Faq";
    case "/PDFViewer":
      return "PDF Viewer";
    case "/privacypolicy":
      return "Privacy Policy";
    case "/termsofservice":
      return "Terms Of Service";
    default:
      return "Page";
  }
};
export default App;
