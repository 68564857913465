
import { combineReducers } from "redux";
import loadingReducer from "./loadingReducer";
import redirectreducer from "./redirectreducer"

const rootReducer = combineReducers({
  isLoading: loadingReducer,
  redirect:redirectreducer,
});

export default rootReducer;
