
import { Carousel } from 'react-bootstrap'; 
import { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../../App.css';
import "../../../index.css";
import { useTranslation } from 'react-i18next';
const Exslider = ({ slides, interval = 50000 }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setIndex((prevIndex) => (prevIndex === slides.length - 1 ? 0 : prevIndex + 1));
        }, interval);

        return () => clearInterval(intervalId);
    }, [slides.length, interval]);
    const { t } = useTranslation();
    return (
        <div className='relative grid items-center overflow-hidden'>
            <div className=' w-full absolute text-white grid items-center  justify-center' style={{ zIndex: "99" }}>
                <div className='relative text-center'>
                </div>
            </div>
            <Carousel activeIndex={index} onSelect={(selectedIndex) => setIndex(selectedIndex)}>

                {slides.map((slide, idx) => (
                    <Carousel.Item key={idx}
                        style={{
                            backgroundImage: `url(${slide.image})`,
                            position: "relative",
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "cover",



                        }} className=' max-[1025px]:h-[200px] lg:h-[100vh]'>

                        <Carousel.Caption >
                            <div className=' container'>
                                <h3 className='text-48 AkagiPro-Black '>{t(slide.caption)}</h3>
                                <h2 className='text-48 AkagiPro-book -m-3'>{t(slide.resort)}</h2>
                                <p className='text-20 AkagiPro-book md:mt-7 max-[767px]:mt-4 md:max-w-[1140px] md:mx-auto'>{t(slide.resorttitel)}</p>
                            </div>
                        </Carousel.Caption>

                    </Carousel.Item>
                ))}
            </Carousel>
        </div>
    );
};

export default Exslider;