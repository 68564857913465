const initialLanguageState = 'English (ca)'; 

const redirectreducer = (state = initialLanguageState, action) => {
  switch (action.type) {
    case 'SET_REDIRECT':
      return action.payload;
    default:
      return state;
  }
};

export default redirectreducer;
