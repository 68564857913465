export const initGA = () => {
    (function() {
      const script = document.createElement('script');
      script.async = true;
      // script.src = 'https://www.googletagmanager.com/gtag/js?id=G-TDYD3F7NJF';
      script.src = 'https://www.googletagmanager.com/gtag/js?id=G-Y5ZP3RJWK6';
      document.head.appendChild(script);
  
      script.onload = () => {
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag;
        gtag('js', new Date());
        gtag('config', 'G-Y5ZP3RJWK6');
      };
    })();
  };
  
  export const trackPageLoadTime = () => {
    if (window.performance) {
      window.addEventListener('load', () => {
        const loadTime = window.performance.timing.domContentLoadedEventEnd - window.performance.timing.navigationStart;
        // eslint-disable-next-line no-undef
        gtag('event', 'timing_complete', {
          name: 'load_time',
          value: loadTime,
          event_category: 'Page Load',
        });
      });
    }
  };
  
  